import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { Chip } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

import PageTemplate from "../page-template";
import { GetRestaurantsData } from "../../controllers/RestaurantsController";
import { RestaurantData } from "../../controllers/ResponseModel";
import { DateParser, isNotEmpty } from "../../constants/Utils";
import { months_es } from "../../constants/Data";

//----------------------------------------//

const columns: GridColDef[] = [
  { field: "id", headerName: "ID", width: 50 },
  { field: "name", headerName: "Nombre", width: 230 },
  { field: "phone", headerName: "Teléfono", width: 120 },
  {
    field: "email",
    headerName: "Email",
    width: 190,
  },
  {
    field: "zip_code",
    headerName: "Cód. Postal",
    type: "number",
    width: 100,
  },
  {
    field: "stripe",
    headerName: "Stripe",
    width: 65,
    renderCell: ({ value }) =>
      isNotEmpty(value) ? (
        <Chip variant="filled" size="small" label="Sí" />
      ) : (
        <Chip variant="filled" size="small" label="No" />
      ),
  },
  {
    field: "state",
    headerName: "Estado",
    width: 90,
    renderCell: ({ value }) => {
      switch (value) {
        case "ACTIVE":
          return <Chip variant="filled" size="small" label="Activo" />;

        case "DISABLED":
          return (
            <Chip variant="filled" size="small" label="Baja" color="default" />
          );

        case "DEBT":
          return (
            <Chip variant="filled" size="small" label="Deuda" color="warning" />
          );

        default:
          return "-";
      }
    },
  },
  {
    field: "enable_date",
    headerName: "Alta",
    type: "date",
    width: 100,
    valueFormatter(params) {
      const d = DateParser(params.value);
      return (
        months_es[d.getUTCMonth()].substring(0, 3) + " - " + d.getUTCFullYear()
      );
    },
  },
  {
    field: "disable_date",
    headerName: "Baja",
    type: "datetime",
    width: 100,
    valueFormatter(params) {
      const d = DateParser(params.value);
      return params.value != null
        ? months_es[d.getUTCMonth()].substring(0, 3) +
            " - " +
            d.getUTCFullYear()
        : "-";
    },
  },
  {
    field: "plan",
    headerName: "Plan",
    width: 90,
    renderCell: ({ value }) => {
      switch (value) {
        case "ALL":
          return <Chip variant="filled" size="small" label="Todo" />;
        case "DELIVERY":
          return <Chip variant="filled" size="small" label="Delivery" />;
        case "BUSINESS":
          return <Chip variant="filled" size="small" label="Business" />;
        case "PREMIUM":
          return <Chip variant="filled" size="small" label="Premium" />;
        default:
          return "-";
      }
    },
  },
  {
    field: "period",
    headerName: "Periodo",
    width: 100,
    renderCell: ({ value }) => {
      switch (value) {
        case "MONTHLY":
          return <Chip variant="filled" size="small" label="Mensual" />;
        case "YEARLY":
          return <Chip variant="filled" size="small" label="Anual" />;
        case "LICENSE":
          return <Chip variant="filled" size="small" label="Licencia" />;
        default:
          return "-";
      }
    },
  },
  {
    field: "fee",
    headerName: "Tarifa",
    width: 100,
    type: "number",
    valueFormatter(params) {
      return (
        parseFloat(params.value + "")
          .toFixed(2)
          .replace(".", ",") + "€"
      );
    },
  },
];

//----------------------------------------//

interface Props {}

const Clientes: React.FC<Props> = ({}) => {
  const [restaurants, setRestaurants] = React.useState<RestaurantData[]>([]);

  // Effect hooks
  useEffect(() => {
    GetRestaurantsData().then((v) => setRestaurants(v.data.restaurants));
  }, []);

  // Handlers

  return (
    <PageTemplate
      id="clientes"
      title="Clientes"
      subtitle="Directorio de clientes"
    >
      <Box sx={{ p: 2 }} style={{ height: 700 }}>
        {restaurants && (
          <DataGrid
            rows={restaurants.map((rd) => {
              return {
                id: rd.id,
                name: rd.name,
                phone: rd.phone,
                email: rd.email,
                zip_code: rd.zip_code,
                stripe: rd.stripe_id,
                state: rd.state,
                enable_date: rd.business_data.enable_date,
                disable_date: rd.business_data.disable_date,
                plan: rd.business_data.plan,
                period: rd.business_data.payment_period,
                fee: rd.business_data.fee,
              };
            })}
            columns={columns}
            // pageSize={5}
            // rowsPerPageOptions={[5]}
            // checkboxSelection
          />
        )}
      </Box>
    </PageTemplate>
  );
};

export default Clientes;
