const API_VERSION = "v1/dashboard/";
const LOCAL_API_ENDPOINT = "http://localhost:8000/api/";
const PRE_API_ENDPOINT = "https://preapi.fooddynamic.es/api/";
const PRO_API_ENDPOINT = "https://api.fooddynamic.es/api/";
const VPS_PRE_ENDPOINT = "https://preapi.vps.fooddynamic.es/api/";
export const API_ENDPOINT = VPS_PRE_ENDPOINT + API_VERSION;

export const enum RESOURCES {
  balances = "balances",
  restaurants = "restaurants",
  features = "features",
  customers = "customers",
  summaries = "summaries",
}

export const enum METHODS {
  GET = "GET",
  POST = "POST",
}

export const enum HTTP_R_CODE {
  OK = 200,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  SERVER_ERROR = 500,
}

export interface FetchResult<T> {
  status: HTTP_R_CODE;
  message: string;
  data: T;
}

export const JSON_HEADERS = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

export const URIBuilder = (...args: string[]) => {
  let URI = "";
  const length = args.length;
  args.forEach((res, i) => {
    URI += res;
    if (length > 1 && i < length - 1) URI += "/";
  });

  return URI;
};
