import React, { Suspense, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { Skeleton, Tab, Tabs, Typography } from "@mui/material";

import PageTemplate from "../page-template";
import { RestaurantType } from "../../constants/Types";
import { GetRestaurantsIds } from "../../controllers/RestaurantsController";

const InfPedidosClientes = React.lazy(() => import("./1_PedidosClientesMes"));
const InfNumeroClientes = React.lazy(() => import("./2_NumeroClientesMes"));
const InfFuncCliente = React.lazy(() => import("./3_FuncionalidadClienteMes"));

//----------------------------------------//

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
}));

//----------------------------------------//

interface Props {}

const Informes: React.FC<Props> = ({}) => {
  const [restaurants, setRestaurants] = React.useState<RestaurantType[]>([]);
  const [tab, setTab] = useState(0);

  // Effect hooks
  useEffect(() => {
    GetRestaurantsIds().then((v) => setRestaurants(v.data.restaurants));
  }, []);

  // Handlers
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  return (
    <PageTemplate
      id="informes"
      title="Informes"
      subtitle="Informes de clientes y funcionalidades por mes"
    >
      <Item>
        <Tabs
          variant="scrollable"
          value={tab}
          onChange={handleTabChange}
          aria-label="Selector de informes"
          sx={{
            borderRight: 1,
            borderColor: "divider",
          }}
        >
          <Tab
            sx={{
              textTransform: "none",
              alignSelf: "self-start",
            }}
            label="Pedidos por cliente"
            {...a11yProps(0)}
          />
          <Tab
            sx={{
              textTransform: "none",
              alignSelf: "self-start",
            }}
            label="Número de clientes"
            {...a11yProps(1)}
          />
          <Tab
            sx={{
              textTransform: "none",
              alignSelf: "self-start",
            }}
            label="Funcionalidad por cliente"
            {...a11yProps(2)}
          />
        </Tabs>
      </Item>

      <Item>
        <TabPanel value={tab} index={0}>
          <Suspense fallback={<Skeleton variant="rounded" height={200} />}>
            <InfPedidosClientes restaurants={restaurants} />
          </Suspense>
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <Suspense fallback={<Skeleton variant="rounded" height={200} />}>
            <InfNumeroClientes />
          </Suspense>
        </TabPanel>
        <TabPanel value={tab} index={2}>
          <Suspense fallback={<Skeleton variant="rounded" height={200} />}>
            <InfFuncCliente restaurants={restaurants} />
          </Suspense>
        </TabPanel>
      </Item>
    </PageTemplate>
  );
};

export default Informes;
