export function RandomColorGenerator(i: number) {
  return (
    ((Math.random() * 255 * (i + 1)) % 255) +
    ", " +
    ((Math.random() * 255 * (i + 1)) % 255) +
    ", " +
    ((Math.random() * 255 * (i + 1)) % 255)
  );
}

export function isNotEmpty(v: any) {
  const str = v + "";
  return v != "" && v != undefined && v != null;
}

export function DateParser(v: string | Date) {
  const str = v + "";
  return new Date(v);
}
