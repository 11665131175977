import React from "react";
import "./page-template.css";

interface Props {
  id: string;
  children?: React.ReactNode;
  title: string;
  subtitle?: string;
}

const PageTemplate: React.FC<Props> = ({ id, children, title, subtitle }) => {
  return (
    <div id={id} className="page-container">
      <h1 className="page-title">{title}</h1>
      <h3 className="page-subtitle">{subtitle}</h3>
      <main className="page-content">{children}</main>
    </div>
  );
};

export default PageTemplate;
