// import React from "react";
import { Routes, Route, Outlet, Link } from "react-router-dom";
import ResponsiveAppBar from "./components/ResponsiveAppBar/ResponsiveAppBar";
// import logo from "./logo.svg";
import Informes from "./pages/informes/Informes";
// import "./App.css";
import PageTemplate from "./pages/page-template";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import red from "@mui/material/colors/red";
import Catalogos from "./pages/catalogos/Catalogos";
import Clientes from "./pages/clientes/Clientes";

const theme = createTheme({
  palette: {
    primary: red,
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div id="app">
        {/* Routes nest inside one another. Nested route paths build upon
            parent route paths, and nested route elements render inside
            parent route elements. See the note about <Outlet> below. */}
        <Routes>
          <Route path="/" element={<Layout />}>
            {/* <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="dashboard" element={<Dashboard />} /> */}
            {/* Using path="*"" means "match anything", so this route acts like a
          catch-all for URLs that we don't have explicit routes for. */}
            <Route path="informes" element={<Informes />} />
            <Route path="clientes" element={<Clientes />} />
            <Route path="catalogos" element={<Catalogos />} />
            <Route
              index
              path="*"
              element={
                <PageTemplate id="empty" title="404 - Página no encontrada" />
              }
            />
          </Route>
        </Routes>
      </div>
    </ThemeProvider>
  );
}

function Layout() {
  return (
    <div>
      <ResponsiveAppBar />
      <Outlet />
    </div>
  );
}

export default App;
