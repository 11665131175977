import {
  API_ENDPOINT,
  FetchResult,
  HTTP_R_CODE,
  JSON_HEADERS,
  METHODS,
  RESOURCES,
  URIBuilder,
} from ".";
import {
  CustomerBalanceByMonth,
  FeaturesByMonth,
  OrdersByMonth,
} from "./ResponseModel";

export async function GetOrdersBalance(
  restaurants_ids?: string[],
  year?: number
) {
  const resource = URIBuilder(RESOURCES.restaurants, RESOURCES.balances);
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResult<OrdersByMonth>;
  let DefaultResponse: Response;

  const result: Response = await fetch(url, {
    method: METHODS.POST,
    headers: JSON_HEADERS,
    body: JSON.stringify({ restaurants_ids: restaurants_ids, year: year }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.status != HTTP_R_CODE.OK) {
        throw new Error(JSON.stringify(res.message));
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      DefaultResponse = {
        data: { restaurants_monthly_orders: [] },
        message: error,
        status: HTTP_R_CODE.SERVER_ERROR,
      };
      return DefaultResponse;
    });

  return result;
}

export async function GetRestaurantFeatures(
  restaurant_id: string,
  year: number
) {
  const resource = URIBuilder(
    RESOURCES.restaurants,
    restaurant_id,
    RESOURCES.features
  );
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResult<FeaturesByMonth>;
  let DefaultResponse: Response;

  const result: Response = await fetch(url, {
    method: METHODS.POST,
    headers: JSON_HEADERS,
    body: JSON.stringify({ year: year }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.status != HTTP_R_CODE.OK) {
        throw new Error(JSON.stringify(res.message));
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      DefaultResponse = {
        data: {
          restaurant_monthly_features: {
            app: [],
            orders: [],
            delivery: [],
            takeaway: [],
            local: [],
            reservations: [],
          },
        },
        message: error,
        status: HTTP_R_CODE.SERVER_ERROR,
      };
      return DefaultResponse;
    });

  return result;
}

export async function GetCustomerBalance(year: number) {
  const resource = URIBuilder(RESOURCES.customers, RESOURCES.balances);
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResult<CustomerBalanceByMonth>;
  let DefaultResponse: Response;

  const result: Response = await fetch(url, {
    method: METHODS.POST,
    headers: JSON_HEADERS,
    body: JSON.stringify({ year: year }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.status != HTTP_R_CODE.OK) {
        throw new Error(JSON.stringify(res.message));
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      DefaultResponse = {
        data: {
          monthly_customers: { gained: [], lost: [] },
        },
        message: error,
        status: HTTP_R_CODE.SERVER_ERROR,
      };
      return DefaultResponse;
    });

  return result;
}
