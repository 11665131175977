import {
  API_ENDPOINT,
  FetchResult,
  HTTP_R_CODE,
  JSON_HEADERS,
  METHODS,
  RESOURCES,
  URIBuilder,
} from ".";
import { RestaurantData } from "./ResponseModel";

export async function GetRestaurantsIds() {
  const resource = URIBuilder(RESOURCES.restaurants);
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResult<{
    restaurants: {
      id: string;
      name: string;
    }[];
  }>;
  let DefaultResponse: Response;

  const result: Response = await fetch(url, {
    method: METHODS.GET,
    headers: JSON_HEADERS,
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.status != HTTP_R_CODE.OK) {
        throw new Error(JSON.stringify(res.message));
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      DefaultResponse = {
        data: { restaurants: [] },
        message: error,
        status: HTTP_R_CODE.SERVER_ERROR,
      };
      return DefaultResponse;
    });

  return result;
}

export async function GetRestaurantsData() {
  const resource = URIBuilder(RESOURCES.restaurants, RESOURCES.summaries);
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResult<{ restaurants: RestaurantData[] }>;
  let DefaultResponse: Response;

  const result: Response = await fetch(url, {
    method: METHODS.GET,
    headers: JSON_HEADERS,
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.status != HTTP_R_CODE.OK) {
        throw new Error(JSON.stringify(res.message));
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      DefaultResponse = {
        data: { restaurants: [] },
        message: error,
        status: HTTP_R_CODE.SERVER_ERROR,
      };
      return DefaultResponse;
    });

  return result;
}
