import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
// import "./App.css";
import PageTemplate from "../page-template";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Theme, useTheme } from "@mui/material/styles";
import { GetOrdersBalance } from "../../controllers/InformesController";
import { months } from "../../constants/Data";
import {
  Button,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { GetRestaurantsIds } from "../../controllers/RestaurantsController";

/**
 * ChartJS
 */
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Chart.js Line Chart",
    },
  },
};

const labels = months;

let data = {
  labels,
  datasets: [
    {
      label: "Pedidos totales",
      data: labels.map((v, i) => i + 1),
      borderColor: "rgb(255, 99, 132)",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
    {
      label: "Delivery / Takeaway",
      data: labels.map((v, i) => i * i),
      borderColor: "rgb(53, 162, 235)",
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
    {
      label: "Pedidos Cliente",
      data: labels.map((v, i) => i * i * 2),
      borderColor: "rgb(53, 235, 162)",
      backgroundColor: "rgba(53, 235, 162, 0.5)",
    },
  ],
};

//----------------------------------------//

// function getStyles(name: string, personName: readonly string[], theme: Theme) {
//   return {
//     fontWeight:
//       personName.indexOf(name) === -1
//         ? theme.typography.fontWeightRegular
//         : theme.typography.fontWeightMedium,
//   };
// }
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    // style: {
    //   maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    //   width: 250,
    // },
  },
};

interface Props {}

const Catalogos: React.FC<Props> = ({}) => {
  // const [data, setData] = useState<
  //   { id: string; name: string; orders: { orders: number; month: string }[] }[]
  // >([]);
  const [chartData, setChartData] = useState<{
    labels: string[];
    datasets: any[];
  }>({ labels: months, datasets: [] });
  const [year, setYear] = useState<number>(new Date().getFullYear());
  // const theme = useTheme();
  const [selectedRestaurants, setSelectedRestaurants] = React.useState<
    string[]
  >([]);
  const [restaurants, setRestaurants] = React.useState<
    {
      id: string;
      name: string;
    }[]
  >([]);

  const handleChange = (
    event: SelectChangeEvent<typeof selectedRestaurants>
  ) => {
    const {
      target: { value },
    } = event;
    setSelectedRestaurants(
      typeof value === "string" ? value.split(",") : value
    );
  };

  // Effect hooks
  useEffect(() => {
    searchDataHandler();
    GetRestaurantsIds().then((v) => setRestaurants(v.data.restaurants));
  }, []);

  // Handlers
  function searchDataHandler() {
    GetOrdersBalance(selectedRestaurants, year).then((res) => {
      setChartData({
        labels: months,
        datasets: res.data.restaurants_monthly_orders.map((m, i) => {
          const randomColor =
            ((Math.random() * 255 * (i + 1)) % 255) +
            ", " +
            ((Math.random() * 255 * (i + 1)) % 255) +
            ", " +
            ((Math.random() * 255 * (i + 1)) % 255);
          return {
            label: m.name,
            data: m.orders.map((v) => v.orders),
            borderColor: "rgb(" + randomColor + ")",
            backgroundColor: "rgba(" + randomColor + ", 0.5)",
          };
        }),
      });
    });
  }

  return (
    <PageTemplate
      id="catalogos"
      title="Catálogos"
      subtitle="Creación y edición de catálogos de clientes"
    >
    
    </PageTemplate>
  );
};

export default Catalogos;
